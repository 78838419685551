import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { AlertMessage } from "@bluesilodev/timhutcomponents";

import { onAlert, clear } from "utils/alert";

export const capitalize_first_letter = (string) => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};

const AlertService = ({ id }) => {
  const [alert, setAlert] = useState({});

  useEffect(() => {
    let isMounted = true;

    const subscription = onAlert(id).subscribe((notif) => {
      if (isMounted) {
        setAlert(notif);
        setTimeout(() => {
          if (isMounted) setAlert({});
        }, 4000);
      }
    });

    return () => {
      clear(id);
      subscription.unsubscribe();
      isMounted = false;
    };
  }, [id]);

  return (
    <div
      className="fixed top-10 right-10"
      style={{ minWidth: "250px", zIndex: 999999 }}
    >
      {alert.title && (
        <AlertMessage
          type={alert.type}
          title={capitalize_first_letter(alert.title)}
          message={alert.message}
          dismissSecond={4000}
        />
      )}
    </div>
  );
};

export default AlertService;
