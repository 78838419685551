import React from "react";
import { Accordion, Button, InputNumber } from "@bluesilodev/timhutcomponents";
import { useFormik } from "formik";
import * as Yup from "yup";
import SaveIconSVG from "assets/icon/SaveIconSVG/SaveIconSVG";
import FetchingAndError from "components/fetchingAndError";
import { useAlertApi } from "services/alert-api";
import {
  useFetchLeaveSettingsQuery,
  useAddLeaveSettingsMutation,
} from "store/apis/leaveSettingsApi";

const LeaveSettings = () => {
  // Formik
  const formik = useFormik({
    initialValues: {
      monthlyLeave: 0,
    },
    validationSchema: Yup.object().shape({
      monthlyLeave: Yup.number().required("Monthly Leave is required"),
    }),
    onSubmit: (values) => {
      addLeaveSettings({
        monthlyExecutiveLeaveLimit: values.monthlyLeave || 0,
      });
    },
  });
  // Redux Toolkit
  const {
    data: dataSetting,
    error: errorLeaveSetting,
    isFetching: isFetchingLeaveSetting,
  } = useFetchLeaveSettingsQuery(null, { refetchOnMountOrArgChange: true });
  const [addLeaveSettings, addLeaveSettingsResults] =
    useAddLeaveSettingsMutation();

  React.useEffect(() => {
    if (dataSetting) {
      formik.setFieldValue(
        "monthlyLeave",
        dataSetting.data.monthlyExecutiveLeaveLimit || 0,
      );
    }
  }, [dataSetting]);
  useAlertApi(
    addLeaveSettingsResults,
    () => {},
    () => {},
    () => "Leave Settings Success Updated",
  );
  return (
    <div className="w-full h-max relative mt-5 p-1 gap-5">
      <div className="w-full flex justify-end">
        <div className="">
          <Button
            label={
              <div className="flex items-center gap-1">
                <SaveIconSVG color={"white"} />
                <div>Save</div>
              </div>
            }
            style={"solid"}
            className={"w-[250px]"}
            onClick={() => formik.submitForm()}
          />
        </div>
      </div>

      <div className="w-full mt-5">
        <FetchingAndError
          isFetching={isFetchingLeaveSetting}
          isError={errorLeaveSetting}
        >
          <Accordion
            title={<div>Leave Excessive</div>}
            icons={[]}
            children={
              <div className="w-4/12">
                <InputNumber
                  label={"Monthly Excessive Leave Limits"}
                  name={"LeaveExcessive"}
                  unit={"Days"}
                  min={0}
                  value={formik.values.monthlyLeave}
                  onChange={(val) => {
                    formik.setFieldValue(
                      "monthlyLeave",
                      isNaN(val) || !val ? 0 : val,
                    );
                  }}
                />
              </div>
            }
          />
        </FetchingAndError>
      </div>
    </div>
  );
};

export default LeaveSettings;
