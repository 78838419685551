import React, { useState } from "react";
import { InputSelect, SearchBox, Button } from "@bluesilodev/timhutcomponents";
import UserAddIcon from "assets/icon/UserAddSVG/UserAdd";
import FetchingAndError from "components/fetchingAndError";
import WorkingDayDataTable from "pages/WorkingDay/tables";
import axios from "services/axios";
import { useFetchLeaveTypeQuery } from "store/apis/leaveTypeApi";
import { useFetchLeaveWorkingDayQuery } from "store/apis/leaveReportApi";
import { useSelector, useDispatch } from "react-redux";
import { setPaginateAllUser } from "store/slices/user";
import { getMonthDateRange } from "utils/common";

const dataTableDummy = [];
const monthOptions = [
  { label: "January", value: 1 },
  { label: "February", value: 2 },
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "May", value: 5 },
  { label: "June", value: 6 },
  { label: "July", value: 7 },
  { label: "August", value: 8 },
  { label: "September", value: 9 },
  { label: "October", value: 10 },
  { label: "November", value: 11 },
  { label: "December", value: 12 },
];

// const leaveTypeMapping = {
//   "17339-7660-3231": "Sick Leave",
//   "17339-7654-2980": "Annual Leave",
//   "17407-0561-3526": "Time Off in Lieu",
// };

// const leaveTypeIDs = ["17339-7660-3231", "17339-7654-2980", "17407-0561-3526"];

const WorkingDay = () => {
  // Data State
  const dispatch = useDispatch();
  const [allDataTable, setAllDataTable] = useState(dataTableDummy);
  const [dataTable, setDataTable] = useState(dataTableDummy);
  // const [paginate, setPaginate] = useState({
  //   totalData: 0,
  //   rowsPerPage: 10,
  //   currentPage: 1,
  // });
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const [filterData, setFilterData] = useState(() => {
    const { firstDay, lastDay } = getMonthDateRange(currentYear, currentMonth);
    return {
      search: "",
      year: currentYear,
      month: currentMonth,
      // startDate: firstDay,
      // endDate: lastDay,
    };
  });

  // Element State
  const [timerStopTyping, setTimerStopTyping] = useState(null);
  const { currentRole, stateAllUserPage } = useSelector(
    (state) => state.userData,
  );

  // Redux Toolkit
  const {
    data: dataLeaveType,
    error: errorLeaveType,
    isFetching: isFetchingLeaveType,
  } = useFetchLeaveTypeQuery(null, { refetchOnMountOrArgChange: true });

  const {
    data: dataWorkingDay,
    error: errorWorkingDay,
    isFetching: isFetchingWorkingDay,
    refetch,
  } = useFetchLeaveWorkingDayQuery(
    {
      ...filterData,
      limit: stateAllUserPage?.rowsPerPage,
      page: stateAllUserPage?.currentPage,
      currentRole: currentRole,
    },
    { refetchOnMountOrArgChange: true },
  );

  React.useEffect(() => {
    // window.localStorage.removeItem("persist:root");

    // setFilterData(allQuery);

    setDataTable((oldV) => {
      return allDataTable.filter((value) => {
        const searchTrue = filterData.search
          ? Object.keys(value).some((key) =>
              String(value[key]).includes(filterData.search),
            )
          : true;
        return searchTrue;
      });
    });
    // console.log("this shouldnt run");
  }, [filterData.search]);

  React.useEffect(() => {
    const year = filterData?.year || currentYear;

    if (dataWorkingDay && dataLeaveType) {
      dispatch(
        setPaginateAllUser({
          rowsPerPage: dataWorkingDay?.limit,
          currentPage: dataWorkingDay?.page,
          totalData: dataWorkingDay?.totalDocs,
        }),
      );
    }

    setDataTable((oldVal) => {
      let returnData = [];
      if (dataWorkingDay && dataLeaveType) {
        // const leaveTypeMap = new Map(
        //   dataLeaveType?.data?.map((val) => [val.uId, val.name]) || [],
        // );

        // const filteredLeaveTypes = dataLeaveType?.data?.filter((val) =>
        //   Object.keys(leaveTypeMapping).includes(val.uId),
        // );

        // 2. Create leaveTypeMap using filtered leave types
        // const leaveTypeMap = new Map(
        //   filteredLeaveTypes.map((val) => [val.uId, leaveTypeMapping[val.uId]]),
        // );
        const leaveTypeMap = new Map(
          dataLeaveType?.data?.map((val) => [val.uId, val.name]) || [],
        );

        // console.log("data working day", dataWorkingDay);

        returnData = Array.isArray(dataWorkingDay?.data?.mainData)
          ? dataWorkingDay.data.mainData.map((val) => {
              const singleData = {
                // employee:
                //   val.employeeInformation.firstName +
                //   " " +
                //   val.employeeInformation.lastName,
                employee: val?.firstName + " " + val?.lastName,
                employeeID: val?.employeeID,
                userID: val?._id,
                uId: val?.uId,
                photo: val?.photo?.[0]?.link || "",
                jobPosition: {
                  position:
                    val?.userInformation?.employeementDetail?.jobPosition || "",
                  department:
                    val?.userInformation?.employeementDetail?.departments || "",
                },
                leaveRecords: val?.leaveRecords?.leaveRecords || [],
                totalDaysWork: val?.availableWorkingDays,
              };

              // Object.values(leaveTypeMapping).forEach((leaveTypeName) => {
              //   singleData[leaveTypeName] = 0;
              // });

              // if (
              //   Array.isArray(val.leaveRecords) &&
              //   val.leaveRecords.length > 0
              // ) {
              //   val.leaveRecords.forEach((record) => {
              //     const leaveTypeName = leaveTypeMap.get(record.leaveTypeID);
              //     if (leaveTypeName) {
              //       singleData[leaveTypeName] = record.ytdTaken;
              //     }
              //   });
              // }

              // if (Array.isArray(val.leaveRecords)) {
              //   val.leaveRecords.forEach((record) => {
              //     const leaveTypeName = leaveTypeMap.get(record.leaveTypeID);
              //     if (leaveTypeName) {
              //       singleData[leaveTypeName] = record.ytdTaken;
              //     }
              //   });
              // }

              return singleData; // Skip if no matching leave type
            })
          : [];
      } else {
        returnData = [];
      }

      setAllDataTable(returnData);
      return returnData;
    });
  }, [dataWorkingDay, dataLeaveType]);

  // Filter leave types before passing them as additionalLeaveTypeColumn
  // const additionalLeaveTypeColumn = dataLeaveType
  //   ? dataLeaveType.data.filter((leaveType) =>
  //       leaveTypeIDs.includes(leaveType.uId),
  //     )
  //   : [];
  const additionalLeaveTypeColumn = dataLeaveType?.data || [];

  //   console.info("additional leave type column", additionalLeaveTypeColumn);

  // React.useEffect(() => {
  // const { firstDay, lastDay } = getMonthDateRange(
  //   filterData.year,
  //   filterData.month,
  // );
  //   console.count("how many tims this run");
  //   setFilterData((oldV) => ({
  //     ...oldV,
  //     startDate: firstDay,
  //     endDate: lastDay,
  //   }));
  // }, [filterData.month, filterData.year]);

  // React.useEffect(() => {
  //   const { firstDay, lastDay } = getMonthDateRange(
  //     filterData.year,
  //     filterData.month,
  //   );

  //   // console.count("how many times this run");

  //   setFilterData((oldV) => {
  //     // Only update if values have changed
  //     if (oldV.startDate === firstDay && oldV.endDate === lastDay) {
  //       return oldV;
  //     }
  //     return {
  //       ...oldV,
  //       startDate: firstDay,
  //       endDate: lastDay,
  //     };
  //   });
  // }, [filterData.month, filterData.year]);

  // const prevFilterDataRef = React.useRef({ startDate: null, endDate: null });

  // React.useEffect(() => {
  //   const { startDate, endDate } = filterData;
  //   const prevStartDate = prevFilterDataRef.current.startDate;
  //   const prevEndDate = prevFilterDataRef.current.endDate;

  //   // Only trigger refetch if startDate or endDate has actually changed
  //   if (startDate !== prevStartDate || endDate !== prevEndDate) {
  //     // console.count("why run two times");
  //     refetch();

  //     // Update ref values after refetch
  //     prevFilterDataRef.current = { startDate, endDate };
  //   }
  // }, [filterData.startDate, filterData.endDate, refetch]);

  // const refetchCalled = React.useRef(false);

  // React.useEffect(() => {
  //   if (filterData.startDate && filterData.endDate && !refetchCalled.current) {
  //     refetchCalled.current = true;
  //     console.count("why run two times");
  //     refetch();
  //     setTimeout(() => {
  //       refetchCalled.current = false; // Reset after execution
  //     }, 100);
  //   }
  // }, [filterData, refetch]);

  const clickExportLeaveBalance = () => {
    axios
      .get(
        `/api/leave/get-working-day?export=true&${Object.entries(
          {
            ...filterData,
            limit: stateAllUserPage.rowsPerPage,
            page: stateAllUserPage.currentPage,
            currentRole,
          } || {},
        )
          .map((val) =>
            !["", "undefined"].includes(val[0]) ? `${val[0]}=${val[1]}` : "",
          )
          .join("&")}`,
      )
      .then((response) => {
        const href = URL.createObjectURL(
          new Blob([response.data], { type: "text/csv;charset=utf-8," }),
        );
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "Timhut_working_days.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  };

  const onChangeTypeSearch = (e) => {
    clearTimeout(timerStopTyping);
    const newTimer = setTimeout(() => {
      setFilterData((oldV) => ({ ...oldV, search: e.target.value }));
    }, 500);
    setTimerStopTyping(newTimer);
  };

  const handlePaginationChange = (type, value) => {
    dispatch(setPaginateAllUser({ ...stateAllUserPage, [type]: value }));
  };

  return (
    <div className="p-1 mt-5 ">
      <div className="flex justify-between w-full gap-5">
        <div className="flex gap-3 min-w-[60%]">
          <InputSelect
            title={"Year"}
            options={Array(50)
              .fill()
              .map((_, idx) => 1980 + idx)
              .map((val) => ({ label: val, value: val }))}
            defaultValue={{ label: currentYear, value: currentYear }}
            onChange={(e) =>
              setFilterData((oldV) => ({ ...oldV, year: e.target.value }))
            }
          />

          <InputSelect
            title={"Month"}
            options={monthOptions}
            disableSort={true}
            // defaultValue={{ label: currentMonth, value: currentMonth }}
            defaultValue={monthOptions.find((m) => m.value === currentMonth)}
            onChange={(e) =>
              setFilterData((oldV) => ({ ...oldV, month: e.target.value }))
            }
          />
        </div>

        <div className="flex gap-3 max-w-[40%]">
          <SearchBox
            placeholder="Search"
            onChange={(e) => onChangeTypeSearch(e)}
          />

          <Button
            // className=" bg-white text-[#DD7224] hover:text-white hover:bg-[#DD7224]"
            onClick={clickExportLeaveBalance}
            label={
              <div className="flex gap-1 px-4">
                <UserAddIcon color="currentColor" />
                <div>Export</div>
              </div>
            }
          />
        </div>
      </div>

      <div className="w-full mt-5">
        <FetchingAndError
          isFetching={isFetchingWorkingDay && isFetchingLeaveType}
          isError={errorWorkingDay && errorLeaveType}
        >
          <WorkingDayDataTable
            dataTable={dataTable}
            currentPage={stateAllUserPage?.currentPage}
            totalData={stateAllUserPage?.totalData}
            rowsPerPage={stateAllUserPage?.rowsPerPage}
            onChangeCurrentPage={(val) =>
              handlePaginationChange("currentPage", val + 1)
            }
            onChangeRowsPerPage={(val) =>
              handlePaginationChange("rowsPerPage", val)
            }
            additionalLeaveTypeColumn={additionalLeaveTypeColumn}
          />
        </FetchingAndError>
      </div>
    </div>
  );
};

export default WorkingDay;
