import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Layout from "layouts";
import LeaveReports from "pages/leaveReports";
import LeaveBalance from "pages/leaveBalance";
import LeaveExpired from "pages/leaveExpired";
import LeaveSettings from "pages/leaveSettings";
import LeaveType from "pages/leaveType";
import WorkingDay from "pages/WorkingDay";
import LeaveDetails from "pages/leaveReports/leaveDetails";
import ExcessiveLeave from "pages/leaveReports/excessiveLeave";
import LeaveBalanceDetails from "pages/leaveBalance/leaveBalanceDetails";
import LeaveLog from "pages/leaveLog";

const AppRouter = () => {
  return (
    <Router basename={window.__POWERED_BY_QIANKUN__ ? "/leave" : "/"}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<LeaveReports />} />
          <Route path="leave-balance" element={<LeaveBalance />} />
          <Route path="leave-expired" element={<LeaveExpired />} />
          <Route path="leave-type" element={<LeaveType />} />
          <Route path="leave-log" element={<LeaveLog />} />
          <Route path="leave-settings" element={<LeaveSettings />} />
          <Route path="working-day" element={<WorkingDay />} />
          <Route
            path="leave-details/:userID/:leaveID"
            element={<LeaveDetails />}
          />
          <Route path="leave-excessive/:userID" element={<ExcessiveLeave />} />
          <Route
            path="leave-balance-details/:userID"
            element={<LeaveBalanceDetails />}
          />
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRouter;
