import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "services/axios";

const ENDPOINT = process.env.REACT_APP_API_URL;
const API = `${ENDPOINT}/api/leave`;

const leaveReportApi = createApi({
  reducerPath: "leaveReport",
  baseQuery: axiosBaseQuery({
    baseUrl: API,
  }),
  endpoints(builder) {
    return {
      fetchLeave: builder.query({
        providesTags: (result, error) => {
          const tags = [];
          result &&
            tags.push(
              ...result.data.mainData.map((leaveReports) => ({
                type: "LeaveReports",
                id: leaveReports._id,
              })),
            );
          tags.push({ type: "LeaveReportsAll" });
          return tags;
        },
        query: (filter) => {
          return {
            // url: `/get?approval=${filter.approval}&startDate=${filter.startDate}&endDate=${filter.endDate}&location=${filter.location}&department=${filter.department}&search=${filter.search}`,
            url: `/get?${Object.entries(filter || {})
              .map((val) =>
                !["", "undefined"].includes(val[0])
                  ? `${val[0]}=${val[1]}`
                  : "",
              )
              .join("&")}`,
            method: "GET",
          };
        },
      }),
      // fetchExportLeave: builder.query({
      //   query: (filter) => {
      //     return {
      //       url: `/get?export=true&${Object.entries(filter || {}).map((val) => !["", "undefined"].includes(val[0]) ? `${val[0]}=${val[1]}` : "").join("&")}`,
      //       method: "GET",
      //     };
      //   },
      // }),
      fetchLeaveExcessiveUser: builder.query({
        providesTags: (result, error) => {
          const tags = [];
          tags.push({ type: "LeaveReportsAll" });
          return tags;
        },
        query: (param) => {
          return {
            // url: `/get-excessive/${param.id}`,
            url: `/get-excessive/${param.id}?${Object.entries(param || {})
              .map((val) =>
                !["", "undefined"].includes(val[0])
                  ? `${val[0]}=${val[1]}`
                  : "",
              )
              .join("&")}`,
            method: "GET",
          };
        },
      }),
      fetchLeaveUser: builder.query({
        providesTags: (result, error) => {
          const tags = [];
          tags.push({ type: "LeaveReportsAll" });
          return tags;
        },
        query: (param) => {
          return {
            url: `/get/${param.id}`,
            method: "GET",
          };
        },
      }),
      fetchLeaveWorkingDay: builder.query({
        providesTags: (result, error) => {
          const tags = [];
          tags.push({ type: "WorkingDaysData" });
          return tags;
        },
        query: (filter) => {
          return {
            // url: `/get?approval=${filter.approval}&startDate=${filter.startDate}&endDate=${filter.endDate}&location=${filter.location}&department=${filter.department}&search=${filter.search}`,
            url: `/get-working-day?${Object.entries(filter || {})
              .map((val) =>
                !["", "undefined"].includes(val[0])
                  ? `${val[0]}=${val[1]}`
                  : "",
              )
              .join("&")}`,
            method: "GET",
          };
        },
      }),
      addLeave: builder.mutation({
        invalidatesTags: (result, error, body) => {
          return [{ type: "LeaveReportsAll" }];
        },
        query: (body) => {
          const formData = new FormData();
          formData.append("organizationID", body.organizationID);
          formData.append("leaveTimes", JSON.stringify(body.leaveTimes));
          formData.append("leaveReason", body.leaveReason);
          formData.append("leaveTypeID", body.leaveTypeID);
          formData.append("userID", body.userID);
          [...body.attachments].forEach((value) => {
            formData.append("attachments", value);
          });
          return {
            url: "/request-leave",
            method: "POST",
            body: formData,
          };
        },
      }),
      updateLeave: builder.mutation({
        invalidatesTags: (result, error, body) => {
          return [{ type: "LeaveReportsAll" }];
        },
        query: (body) => {
          return {
            url: `/updateStatus/${body.leaveID}`,
            method: "PUT",
            body: {
              bySupervisor:
                body.bySupervisor !== undefined
                  ? {
                      status: body.bySupervisor.status,
                      comments: body.bySupervisor.comments,
                    }
                  : null,
              byHr:
                body.byHr !== undefined
                  ? {
                      status: body.byHr.status,
                      comments: body.byHr.comments,
                    }
                  : null,
            },
          };
        },
      }),
      deleteLeave: builder.mutation({
        invalidatesTags: (result, error, body) => {
          return [{ type: "LeaveReportsAll" }];
        },
        query: (body) => {
          return {
            url: `/delete/${body.leaveID}`,
            method: "DELETE",
          };
        },
      }),
    };
  },
});

export const {
  useFetchLeaveQuery,
  // useFetchExportLeaveQuery,
  useFetchLeaveExcessiveUserQuery,
  useFetchLeaveUserQuery,
  useFetchLeaveWorkingDayQuery,
  useAddLeaveMutation,
  useUpdateLeaveMutation,
  useDeleteLeaveMutation,
} = leaveReportApi;
export { leaveReportApi };
