import React, { useState } from "react";
import { InputSelect, SearchBox, Button } from "@bluesilodev/timhutcomponents";
import UserAddIcon from "assets/icon/UserAddSVG/UserAdd";
import FetchingAndError from "components/fetchingAndError";
import LeaveBalanceDataTable from "pages/leaveBalance/tables";
import axios from "services/axios";
import { useFetchLocationDepartmentQuery } from "store/apis/externalApi";
import { useFetchLeaveBalanceQuery } from "store/apis/leaveBalanceApi";
import { useFetchLeaveTypeQuery } from "store/apis/leaveTypeApi";
import { useSelector, useDispatch } from "react-redux";
import { setPaginateAllUser } from "store/slices/user";

const dataTableDummy = [];
/*
const dataTableDummy = [
  {
    employee: "Halim",
    userID: "4567",
    location: "Cafe Johandi",
    uId: "298djdj",
    jobPosition: {
      position: "Cook",
      department: "Operation",
    },
    annualLeave: "12 Days",
    sickLeave: "5 Days",
    offInLine: "1 Days",
    ns: "5 Days",
  },
  {
    employee: "Halim",
    userID: "4567",
    location: "Cafe Johandi",
    uId: "298djdj",
    jobPosition: {
      position: "Cook",
      department: "Operation",
    },
    annualLeave: "12 Days",
    sickLeave: "5 Days",
    offInLine: "1 Days",
    ns: "5 Days",
  },
  {
    employee: "Halim",
    userID: "4567",
    location: "Cafe Halim",
    uId: "298djdj",
    jobPosition: {
      position: "Cook",
      department: "Operation",
    },
    annualLeave: "12 Days",
    sickLeave: "5 Days",
    offInLine: "1 Days",
    ns: "5 Days",
  },
  {
    employee: "Halim",
    userID: "4567",
    location: "Cafe Halim",
    uId: "298djdj",
    jobPosition: {
      position: "Cook",
      department: "Operation",
    },
    annualLeave: "12 Days",
    sickLeave: "5 Days",
    offInLine: "1 Days",
    ns: "5 Days",
  },
  {
    employee: "Halim",
    userID: "4567",
    location: "Cafe Johandi",
    uId: "298djdj",
    jobPosition: {
      position: "Cook",
      department: "Operation",
    },
    annualLeave: "12 Days",
    sickLeave: "5 Days",
    offInLine: "1 Days",
    ns: "5 Days",
  },
  {
    employee: "Halim",
    userID: "4567",
    location: "Cafe Halim",
    uId: "298djdj",
    jobPosition: {
      position: "Cook",
      department: "Operation",
    },
    annualLeave: "12 Days",
    sickLeave: "5 Days",
    offInLine: "1 Days",
    ns: "5 Days",
  },
];
*/

const LeaveBalance = () => {
  // Data State
  const [allDataTable, setAllDataTable] = useState(dataTableDummy);
  const [dataTable, setDataTable] = useState(dataTableDummy);
  const [globalFilter, setGlobalFilter] = useState({ locations: [] });
  const [locations, setLocations] = useState([{ label: "", value: "" }]);
  const [departments, setDepartments] = useState([{ label: "", value: "" }]);
  // const [paginate, setPaginate] = useState({
  //   totalData: 0,
  //   rowsPerPage: 10,
  //   currentPage: 1,
  // });
  const currentYear = new Date().getFullYear();
  const dispatch = useDispatch();

  const [filterData, setFilterData] = useState({
    search: "",
    year: parseInt(currentYear),
    location: "",
    department: "",
  });

  // Element State
  const [timerStopTyping, setTimerStopTyping] = useState(null);
  const { currentRole, stateAllUserPage } = useSelector(
    (state) => state.userData,
  );

  // Redux Toolkit
  const {
    data: dataLocationDep,
    error: errorLocationDep,
    isFetching: isFetchingLocationDep,
  } = useFetchLocationDepartmentQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const {
    data: dataLeaveType,
    error: errorLeaveType,
    isFetching: isFetchingLeaveType,
  } = useFetchLeaveTypeQuery(null, { refetchOnMountOrArgChange: true });
  const {
    data: dataLeaveBalance,
    error: errorLeaveExpired,
    isFetching: isFetchingLeaveExpired,
  } = useFetchLeaveBalanceQuery(
    {
      ...filterData,
      limit: stateAllUserPage?.rowsPerPage,
      page: stateAllUserPage?.currentPage,
      currentRole: currentRole,
    },
    { refetchOnMountOrArgChange: true },
  );

  React.useEffect(() => {
    // window.localStorage.removeItem("persist:root");

    // setFilterData(allQuery);

    setDataTable((oldV) => {
      return allDataTable.filter((value) => {
        const searchTrue = filterData.search
          ? Object.keys(value).some((key) =>
              String(value[key]).includes(filterData.search),
            )
          : true;
        const departmentTrue = filterData.department
          ? value.jobPosition.department === filterData.department
          : true;
        const locationTrue = filterData.location
          ? value.location === filterData.location
          : true;
        return searchTrue && departmentTrue && locationTrue;
      });
    });
    const departmentTempData = globalFilter.locations
      .filter(
        (item) =>
          item.locationName === filterData.location ||
          filterData.location === "",
      )
      .flatMap((entry) => entry.departments)
      .map((item, index) => {
        return {
          label: item.department,
          value: item.department,
        };
      });
    const departmentFInal = [];
    departmentTempData?.forEach((item) => {
      if (!departmentFInal.some((single) => single.label === item.label)) {
        departmentFInal.push({
          label: item.label,
          value: item.value,
        });
      }
    });
    setDepartments(departmentFInal);
  }, [filterData]);

  React.useEffect(() => {
    if (dataLocationDep) {
      const department = [];
      const location = dataLocationDep.data.map((locationVal) => {
        locationVal.departments.forEach((departmentVal) => {
          if (
            department.find((value) => value.value === departmentVal.department)
          )
            return;
          department.push({
            value: departmentVal.department,
            label: departmentVal.department,
          });
        });
        return {
          value: locationVal.locationName,
          label: locationVal.locationName,
        };
      });
      setDepartments(department);
      setLocations(location);
      setGlobalFilter({ locations: dataLocationDep.data });
    }
  }, [dataLocationDep]);

  React.useEffect(() => {
    const year = filterData?.year || currentYear;

    if (dataLeaveBalance && dataLeaveType) {
      dispatch(
        setPaginateAllUser({
          rowsPerPage: dataLeaveBalance?.limit,
          currentPage: dataLeaveBalance?.page,
          totalData: dataLeaveBalance?.totalDocs,
        }),
      );
    }

    setDataTable((oldVal) => {
      let returnData = [];
      if (dataLeaveBalance && dataLeaveType) {
        // setPaginate((oldData) => ({
        //   ...oldData,
        //   totalData: dataLeaveBalance.totalDocs,
        // }));
        const leaveType = dataLeaveType.data.map((val) => ({
          leaveTypeId: val.uId,
          leaveType: val.name,
        }));
        returnData = [
          ...dataLeaveBalance.data.map((val) => {
            const singleData = {
              // employee: val.employeeInformation.userName,
              employee:
                val.employeeInformation.firstName +
                " " +
                val.employeeInformation.lastName,
              employeeID: val.employeeInformation.employeeID,
              userID: val.userID,
              uId: val.uId,
              photo: val.employeeInformation.photo?.[0].link || "",
              jobPosition: {
                position:
                  val.employeeInformation?.userInformation?.employeementDetail
                    ?.jobPosition || "",
                department:
                  val.employeeInformation?.userInformation?.employeementDetail
                    ?.departments || "",
              },
            };
            leaveType.forEach((leaveTypeItem) => {
              // console.log("val", val);
              const valBalance = val?.balance[year]?.find(
                (type) => type.leaveTypeID === leaveTypeItem.leaveTypeId,
              );
              singleData[leaveTypeItem.leaveType] = {
                fullYearEntitlement: valBalance?.fullYearEntitlement,
                ytdBalance: valBalance?.ytdBalance,
              };
            });
            return singleData;
          }),
        ];
      } else {
        returnData = [];
      }
      setAllDataTable(returnData);
      return returnData;
    });
  }, [dataLeaveBalance, dataLeaveType]);

  const clickExportLeaveBalance = () => {
    axios
      .get(
        `/api/leave-balance/get?export=true&${Object.entries(
          {
            ...filterData,
            limit: stateAllUserPage.rowsPerPage,
            page: stateAllUserPage.currentPage,
          } || {},
        )
          .map((val) =>
            !["", "undefined"].includes(val[0]) ? `${val[0]}=${val[1]}` : "",
          )
          .join("&")}`,
      )
      .then((response) => {
        const href = URL.createObjectURL(
          new Blob([response.data], { type: "text/csv;charset=utf-8," }),
        );
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "Timhut_leave_balance.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  };

  const onChangeTypeSearch = (e) => {
    clearTimeout(timerStopTyping);
    const newTimer = setTimeout(() => {
      setFilterData((oldV) => ({ ...oldV, search: e.target.value }));
    }, 500);
    setTimerStopTyping(newTimer);
  };

  const handlePaginationChange = (type, value) => {
    dispatch(setPaginateAllUser({ ...stateAllUserPage, [type]: value }));
  };

  return (
    <div className="p-1 mt-5 ">
      <div className="flex justify-between w-full gap-5">
        <div className="flex gap-3 min-w-[60%]">
          <InputSelect
            title={"Year"}
            options={Array(50)
              .fill()
              .map((_, idx) => 1980 + idx)
              .map((val) => ({ label: val, value: val }))}
            defaultValue={{ label: currentYear, value: currentYear }}
            onChange={(e) =>
              setFilterData((oldV) => ({ ...oldV, year: e.target.value }))
            }
          />

          <InputSelect
            title={"Location"}
            options={locations}
            placeholder="All Locations"
            onChange={(e) =>
              setFilterData((oldV) => ({ ...oldV, location: e.target.value }))
            }
          />

          <InputSelect
            title={"Department"}
            options={departments}
            placeholder="All Departments"
            onChange={(e) =>
              setFilterData((oldV) => ({ ...oldV, department: e.target.value }))
            }
          />
        </div>

        <div className="flex gap-3 max-w-[40%]">
          <SearchBox
            placeholder="Search"
            onChange={(e) => onChangeTypeSearch(e)}
          />

          <Button
            // className=" bg-white text-[#DD7224] hover:text-white hover:bg-[#DD7224]"
            onClick={clickExportLeaveBalance}
            label={
              <div className="flex gap-1 px-4">
                <UserAddIcon color="currentColor" />
                <div>Export</div>
              </div>
            }
          />
        </div>
      </div>

      <div className="w-full mt-5">
        <FetchingAndError
          isFetching={isFetchingLeaveExpired && isFetchingLeaveType}
          isError={errorLeaveExpired && errorLeaveType}
        >
          <LeaveBalanceDataTable
            dataTable={dataTable}
            currentPage={stateAllUserPage?.currentPage}
            totalData={stateAllUserPage?.totalData}
            rowsPerPage={stateAllUserPage?.rowsPerPage}
            onChangeCurrentPage={(val) =>
              handlePaginationChange("currentPage", val + 1)
            }
            onChangeRowsPerPage={(val) =>
              handlePaginationChange("rowsPerPage", val)
            }
            additionalLeaveTypeColumn={dataLeaveType ? dataLeaveType.data : []}
          />
        </FetchingAndError>
      </div>
    </div>
  );
};

export default LeaveBalance;
