import React, { useState } from "react";
import {
  Accordion,
  Button,
  InputSelect,
  InputText,
} from "@bluesilodev/timhutcomponents";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AdjustmentIconSVG from "assets/icon/AdjustmentIconSVG/AdjusmetIconSVG";
import FetchingAndError from "components/fetchingAndError";
import { LeaveBalanceDetailsAdjustmentModalForm } from "pages/leaveBalance/forms";
import { LeaveBalanceDetailsDataTable } from "pages/leaveBalance/tables";
import { useFetchLatestShiftNameByUserIDQuery } from "store/apis/externalApi";
import { useFetchLeaveTypeQuery } from "store/apis/leaveTypeApi";
import { useFetchLeaveBalanceUserQuery } from "store/apis/leaveBalanceApi";

/*
const dataTable = [
  {
    leaveType: "Annual Leave",
    fullYearEntitlement: "12 Days",
    ytdEarned: "8 Days",
    broughtFoward: "4 Days",
    adjusment: "1 Days",
    ytdTaken: "3 Days",
    bfForfeinted: "3 Days",
    pendingForApproval: "1 Days",
    ytdBalance: "6 Days",
    projectedBalance: "10 Days",
  },
  {
    leaveType: "Sick Leave",
    fullYearEntitlement: "12 Days",
    ytdEarned: "8 Days",
    broughtFoward: "4 Days",
    adjusment: "1 Days",
    ytdTaken: "3 Days",
    bfForfeinted: "3 Days",
    pendingForApproval: "1 Days",
    ytdBalance: "6 Days",
    projectedBalance: "10 Days",
  },
  {
    leaveType: "Off In Line",
    fullYearEntitlement: "12 Days",
    ytdEarned: "8 Days",
    broughtFoward: "4 Days",
    adjusment: "1 Days",
    ytdTaken: "3 Days",
    bfForfeinted: "3 Days",
    pendingForApproval: "1 Days",
    ytdBalance: "6 Days",
    projectedBalance: "10 Days",
  },
  {
    leaveType: "NS",
    fullYearEntitlement: "12 Days",
    ytdEarned: "8 Days",
    broughtFoward: "4 Days",
    adjusment: "1 Days",
    ytdTaken: "3 Days",
    bfForfeinted: "3 Days",
    pendingForApproval: "1 Days",
    ytdBalance: "6 Days",
    projectedBalance: "10 Days",
  },
];
*/

const LeaveBalanceDetails = () => {
  let { userID } = useParams();
  // Redux State
  const { currentRole } = useSelector((state) => state.userData);
  // Formik
  const formik = useFormik({
    initialValues: {
      leaveBalanceData: [],
      employeeInformation: {
        photo: "",
        shift: "",
        firstName: "",
        lastName: "",
        employeeID: "",
        userID: "",
        location: "",
        department: "",
        jobPosition: "",
        adjustmentNote: "",
      },
    },
    validationSchema: undefined,
    validateOnBlur: true,
    onSubmit: (values) => {},
  });
  // Element State
  const [isModalOpen, setIsModalOpen] = useState(false);
  // Redux Toolkit
  const {
    data: dataShift,
    error: errorShift,
    isFetching: isFetchingShift,
  } = useFetchLatestShiftNameByUserIDQuery(
    { fetchShiftByUserID: userID || "" },
    { refetchOnMountOrArgChange: true },
  );
  const {
    data: dataLeaveBalanceUser,
    error: errorLeaveBalanceUser,
    isFetching: isFetchingLeaveBalanceUser,
    refetch: refetchLeaveBalanceUser,
  } = useFetchLeaveBalanceUserQuery(
    { id: userID },
    { refetchOnMountOrArgChange: true },
  );

  const {
    data: dataLeaveType,
    error: errorLeaveType,
    isFetching: isFetchingLeaveType,
  } = useFetchLeaveTypeQuery({ refetchOnMountOrArgChange: true });

  // console.log("data leave type", dataLeaveType);
  // console.log("data leave balance user", dataLeaveBalanceUser);

  // React.useEffect(() => {
  //   if (dataShift && dataLeaveBalanceUser) {
  //     const year = new Date().getFullYear();
  //     const {
  //       balance: { [year]: balanceYear },
  //       employeeInformation,
  //       userID,
  //       adjustmentNote,
  //     } = dataLeaveBalanceUser;
  //     formik.setFieldValue("employeeInformation", {
  //       photo: employeeInformation.photo?.[0]?.link || "",
  //       shift: dataShift?.assignShifts?.[0]?.shift?.shiftName || "???",
  //       firstName: employeeInformation.firstName,
  //       lastName: employeeInformation.lastName,
  //       employeeID: employeeInformation.employeeID,
  //       userID: userID,
  //       location: employeeInformation.locations?.address || "",
  //       department:
  //         employeeInformation?.userInformation?.employeementDetail
  //           ?.departments || "",
  //       jobPosition:
  //         employeeInformation?.userInformation?.employeementDetail
  //           ?.jobPosition || "",
  //       adjustmentNote: adjustmentNote,
  //     });
  //     formik.setFieldValue("leaveBalanceData", balanceYear);
  //     // console.log("balance year", balanceYear);
  //   }
  // }, [dataShift, dataLeaveBalanceUser]);

  React.useEffect(() => {
    if (dataShift && dataLeaveBalanceUser && dataLeaveType) {
      const year = new Date().getFullYear();
      const {
        balance: { [year]: balanceYear },
        employeeInformation,
        userID,
        adjustmentNote,
      } = dataLeaveBalanceUser;

      // Get valid leave type IDs from dataLeaveType
      const validLeaveTypeIDs = new Set(
        dataLeaveType?.data?.map((type) => type.uId),
      );

      // Filter balanceYear to include only valid leaveTypeIDs
      const filteredBalanceYear = balanceYear.filter(
        (balance) =>
          balance?.leaveTypeID && validLeaveTypeIDs.has(balance.leaveTypeID),
      );

      console.log("filtered balance year", filteredBalanceYear);

      formik.setFieldValue("employeeInformation", {
        photo: employeeInformation.photo?.[0]?.link || "",
        shift: dataShift?.data?.latestShiftName || "???",
        firstName: employeeInformation.firstName,
        lastName: employeeInformation.lastName,
        employeeID: employeeInformation.employeeID,
        userID: userID,
        location: employeeInformation.locations?.address || "",
        department:
          employeeInformation?.userInformation?.employeementDetail
            ?.departments || "",
        jobPosition:
          employeeInformation?.userInformation?.employeementDetail
            ?.jobPosition || "",
        adjustmentNote: adjustmentNote,
      });

      formik.setFieldValue("leaveBalanceData", filteredBalanceYear);
    }
  }, [dataShift, dataLeaveBalanceUser, dataLeaveType]);

  const handleOpenModal = () => {
    if (!["Admin", "Supervisor"].includes(currentRole)) return;
    setIsModalOpen(true);
  };

  const employeeDetail = (
    <>
      <div className="flex flex-row items-start w-full">
        <div className="border border-[#A3A3AB] rounded-lg p-2 aspect-square min-w-[150px] flex-1">
          <img
            className="object-cover rounded-[5px]"
            src={formik.values.employeeInformation.photo}
            alt="User Profile"
          />
        </div>

        <div className="flex flex-col w-full gap-2 p-1 ml-10">
          <div className="grid grid-cols-3 gap-5">
            <div className={"w-full"}>
              <InputSelect
                title={"Shift"}
                options={[
                  {
                    label: formik.values.employeeInformation.shift,
                    value: formik.values.employeeInformation.shift,
                  },
                ]}
                value={formik.values.employeeInformation.shift}
                disabled={true}
              />
            </div>
            <div className="w-full"></div>
            <div className="w-full"></div>
            <InputText
              title={"First Name"}
              label={<React.Fragment></React.Fragment>}
              value={formik.values.employeeInformation.firstName}
              disabled={true}
            />
            <InputText
              title={"Last Name"}
              label={<React.Fragment></React.Fragment>}
              value={formik.values.employeeInformation.lastName}
              disabled={true}
            />
            <InputText
              title={"Employee ID"}
              label={<React.Fragment></React.Fragment>}
              value={formik.values.employeeInformation.employeeID}
              disabled={true}
            />
            <InputSelect
              title={"Location"}
              options={[
                {
                  label: formik.values.employeeInformation.location,
                  value: formik.values.employeeInformation.location,
                },
              ]}
              value={formik.values.employeeInformation.location}
              disabled={true}
            />
            <InputSelect
              title={"Departments"}
              options={[
                {
                  label: formik.values.employeeInformation.department,
                  value: formik.values.employeeInformation.department,
                },
              ]}
              value={formik.values.employeeInformation.department}
              disabled={true}
            />
            <InputSelect
              title={"Job Positions"}
              options={[
                {
                  label: formik.values.employeeInformation.jobPosition,
                  value: formik.values.employeeInformation.jobPosition,
                },
              ]}
              value={formik.values.employeeInformation.jobPosition}
              disabled={true}
            />
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div className="relative w-full p-1 mt-5 h-max">
      <FetchingAndError
        isFetching={
          isFetchingLeaveBalanceUser || isFetchingLeaveType || isFetchingShift
        }
        isError={errorLeaveBalanceUser}
      >
        <LeaveBalanceDetailsAdjustmentModalForm
          userID={userID}
          adjustmentNote={formik.values.employeeInformation.adjustmentNote}
          dataTable={formik.values.leaveBalanceData}
          refetchLeaveBalanceUser={refetchLeaveBalanceUser}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />

        {["Admin", "Supervisor"].includes(currentRole) && (
          <div className="flex justify-end w-full">
            <div>
              <Button
                label={
                  <div className="flex gap-1 px-4">
                    <AdjustmentIconSVG color={"white"} />
                    <div>Adjust Leave Balance</div>
                  </div>
                }
                style={"solid"}
                className={"w-30px"}
                onClick={handleOpenModal}
              />
            </div>
          </div>
        )}

        <div className="w-full mt-5">
          <Accordion
            title={<div>Employee Details</div>}
            icons={[]}
            children={employeeDetail}
          />
        </div>

        <div className="w-full mt-5">
          <Accordion
            title={<div>Leave Balance Details</div>}
            icons={[]}
            children={
              <div>
                <LeaveBalanceDetailsDataTable
                  leaveBalanceDetailsValue={formik.values.leaveBalanceData}
                />
              </div>
            }
          />
        </div>
      </FetchingAndError>
    </div>
  );
};

export default LeaveBalanceDetails;
