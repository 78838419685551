import React, { useState } from "react";
import { Button } from "@bluesilodev/timhutcomponents";
import { useSelector } from "react-redux";
import PlusIconSVG from "assets/icon/PlusIconSVG/PlusIconSVG";
import FetchingAndError from "components/fetchingAndError";
import {
  LeaveTypeAddModalForm,
  LeaveTypeEditModalForm,
} from "pages/leaveType/forms";
import LeaveTypeDataTable from "pages/leaveType/tables";
import { useAlertApi } from "services/alert-api";
import {
  useFetchLeaveTypeQuery,
  useDeleteLeaveTypeMutation,
} from "store/apis/leaveTypeApi";

const dataTableDummy = [];
/*
const dataTableDummy = [
  {
    leaveType: "Annual Leave",
    defaultAmount: "12 Days",
    gainPerMonth: "1 Days",
    expiredCarryOver: "6 Month",
    maxCarryOver: "5 Days",
  },

  {
    leaveType: "Sick Leave",
    defaultAmount: "12 Days",
    gainPerMonth: "1 Days",
    expiredCarryOver: "6 Month",
    maxCarryOver: "5 Days",
  },

  {
    leaveType: "Off In Line",
    defaultAmount: "12 Days",
    gainPerMonth: "1 Days",
    expiredCarryOver: "6 Month",
    maxCarryOver: "5 Days",
  },

  {
    leaveType: "NS",
    defaultAmount: "12 Days",
    gainPerMonth: "1 Days",
    expiredCarryOver: "6 Month",
    maxCarryOver: "5 Days",
  },
];
*/

const LeaveType = () => {
  const leaveTypeDetailsInitialState = {
    leaveTypeId: "",
    name: "",
    defaultAmount: 0,
    gainPerMonth: 0,
    autoCalculate: false,
    expiredCarryOver: 0,
    maxCarryOver: 0,
  };
  // Redux State
  const { currentRole } = useSelector((state) => state.userData);
  // Data State
  const [dataTable, setDataTable] = useState(dataTableDummy);
  const [paginate, setPaginate] = useState({
    totalData: 0,
    rowsPerPage: 10,
    currentPage: 1,
  });
  const [leaveTypeDetailsEdit, setLeaveTypeDetailsEdit] = useState({
    ...leaveTypeDetailsInitialState,
    periodOfEmployments: [],
  });
  // Element State
  const [leaveTypeDetailsModal, leaveTypeDetailsOpen] = useState(false);
  const [addLeaveTypeModal, addLeaveTypeOpen] = useState(false);
  // Redux Toolkit
  const {
    data: dataLeaveType,
    error: errorLeaveType,
    isFetching: isFetchingLeaveType,
  } = useFetchLeaveTypeQuery(
    { limit: paginate.rowsPerPage, page: paginate.currentPage },
    { refetchOnMountOrArgChange: true },
  );
  const [deleteLeaveType, deleteLeaveTypeResults] =
    useDeleteLeaveTypeMutation();

  React.useEffect(() => {
    dataLeaveType &&
      setPaginate((oldData) => ({
        ...oldData,
        totalData: dataLeaveType.totalDocs,
      }));
    setDataTable(
      dataLeaveType && !errorLeaveType
        ? dataLeaveType.data.map((val) => ({
            leaveTypeId: val._id,
            name: val.name,
            defaultAmount: val.defaultAmount,
            gainPerMonth: val.gainPerMonth,
            autoCalculate: false,
            isFullLeaveAmount: val.isFullLeaveAmount ?? false,
            expiredCarryOver: val.expiredCarryOver,
            maxCarryOver: val.maxCarryOver,
            periodOfEmployments: val.periodOfEmployments,
            assignTo: val.assignTo,
          }))
        : [],
    );
  }, [dataLeaveType, deleteLeaveTypeResults]);

  const alertSuccessClose = () => {
    addLeaveTypeOpen(false);
  };
  useAlertApi(
    deleteLeaveTypeResults,
    alertSuccessClose,
    () => {},
    () => "leave type deleted Successfully",
  );

  const onEditClickDataTable = (findSingleLeaveType) => {
    if (!["Admin", "Supervisor"].includes(currentRole)) return;

    setLeaveTypeDetailsEdit({
      ...findSingleLeaveType,
      name: findSingleLeaveType.name,
      periodOfEmployments: findSingleLeaveType.periodOfEmployments,
    });
    leaveTypeDetailsOpen(true);
  };

  const onDeleteClickDataTable = (data) => {
    if (!["Admin", "Supervisor"].includes(currentRole)) return;
    deleteLeaveType({
      name: data.name,
    });
  };

  return (
    <div className="w-full h-max relative mt-5 p-1">
      <LeaveTypeAddModalForm
        addLeaveTypeModal={addLeaveTypeModal}
        addLeaveTypeOpen={addLeaveTypeOpen}
      />
      <LeaveTypeEditModalForm
        leaveTypeDetailsModal={leaveTypeDetailsModal}
        leaveTypeDetailsOpen={leaveTypeDetailsOpen}
        leaveTypeDetailsEdit={leaveTypeDetailsEdit}
      />

      {["Admin", "Supervisor"].includes(currentRole) && (
        <div className="w-full flex justify-end">
          <div className="">
            <Button
              label={
                <div className="flex items-center gap-1">
                  <PlusIconSVG color={"white"} />
                  <div>Add New Leave Type</div>
                </div>
              }
              style={"solid"}
              className={"w-[250px]"}
              onClick={() => {
                addLeaveTypeOpen(true);
              }}
            />
          </div>
        </div>
      )}

      <div className="w-full mt-5 ">
        <FetchingAndError
          isFetching={isFetchingLeaveType}
          isError={errorLeaveType}
        >
          <LeaveTypeDataTable
            dataTable={dataTable}
            currentPage={paginate.currentPage}
            totalData={paginate.totalData}
            rowsPerPage={paginate.rowsPerPage}
            onChangeCurrentPage={(val) =>
              setPaginate((oldData) => ({ ...oldData, currentPage: val + 1 }))
            }
            onChangeRowsPerPage={(val) =>
              setPaginate((oldData) => ({ ...oldData, rowsPerPage: val }))
            }
            onEditClick={onEditClickDataTable}
            onDeleteClick={onDeleteClickDataTable}
          />
        </FetchingAndError>
      </div>
    </div>
  );
};

export default LeaveType;
