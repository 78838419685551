import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "services/axios";

const ENDPOINT = process.env.REACT_APP_API_URL;
const API = `${ENDPOINT}/api/leave-type`;

const leaveTypeApi = createApi({
  reducerPath: "leaveType",
  baseQuery: axiosBaseQuery({
    baseUrl: API,
    fetchFn: async (...args) => {
      return fetch(...args);
    },
  }),
  endpoints(builder) {
    return {
      fetchLeaveType: builder.query({
        providesTags: (result, error) => {
          const tags = [];
          result &&
            tags.push(
              ...result.data.map((leaveType) => ({
                type: "LeaveType",
                id: leaveType._id,
              })),
            );
          tags.push({ type: "LeaveTypeAll" });
          return tags;
        },
        query: (filter = {}) => {
          return {
            url: `/get?${Object.entries(filter || {})
              .map((val) =>
                !["", "undefined"].includes(val[0])
                  ? `${val[0]}=${val[1]}`
                  : "",
              )
              .join("&")}`,
            method: "GET",
          };
        },
      }),
      addLeaveType: builder.mutation({
        invalidatesTags: (result, error, body) => {
          return [{ type: "LeaveTypeAll" }];
        },
        query: (body) => {
          return {
            url: "/create",
            method: "POST",
            body: {
              name: body.name,
              defaultAmount: body.defaultAmount,
              // gainPerMonth: body.gainPerMonth,
              expiredCarryOver: body.expiredCarryOver,
              maxCarryOver: body.maxCarryOver,
              periodOfEmployments: body.periodOfEmployments,
              isFullLeaveAmount: body.isFullLeaveAmount,
              // autoCalculate: body.autoCalculate,
              assignTo: body.assignTo,
            },
          };
        },
        // onQueryStarted: async (arg, { queryFulfilled }) => {
        //   console.log("Body data received:", arg);
        //   try {
        //     const response = await queryFulfilled;
        //     console.log("Mutation successful:", response.data);
        //   } catch (error) {
        //     console.error("Mutation failed:", error);
        //   }
        // },
      }),
      updateLeaveType: builder.mutation({
        invalidatesTags: (result, error, body) => {
          return [{ type: "LeaveTypeAll" }];
        },
        query: (body) => {
          return {
            url: `/`,
            method: "PUT",
            body: {
              name: body.name,
              defaultAmount: body.defaultAmount,
              // gainPerMonth: body.gainPerMonth,
              expiredCarryOver: body.expiredCarryOver,
              maxCarryOver: body.maxCarryOver,
              periodOfEmployments: body.periodOfEmployments,
              isFullLeaveAmount: body.isFullLeaveAmount,
              // autoCalculate: body.autoCalculate,
              assignTo: body.assignTo,
            },
          };
        },
      }),
      deleteLeaveType: builder.mutation({
        invalidatesTags: (result, error, body) => {
          return [{ type: "LeaveTypeAll" }];
        },
        query: (body) => {
          return {
            url: `/`,
            method: "DELETE",
            body: {
              name: body.name,
            },
          };
        },
      }),
    };
  },
});

export const {
  useFetchLeaveTypeQuery,
  useAddLeaveTypeMutation,
  useUpdateLeaveTypeMutation,
  useDeleteLeaveTypeMutation,
} = leaveTypeApi;
export { leaveTypeApi };
