import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "services/axios";

const ENDPOINT = process.env.REACT_APP_API_URL;
const API = `${ENDPOINT}/api/leave-balance`;

const leaveBalanceApi = createApi({
  reducerPath: "leaveBalance",
  baseQuery: axiosBaseQuery({
    baseUrl: API,
  }),
  endpoints(builder) {
    return {
      fetchLeaveBalance: builder.query({
        providesTags: (result, error) => {
          const tags = [];
          result &&
            tags.push(
              ...result.data.map((leaveBalance) => ({
                type: "LeaveBalance",
                id: leaveBalance._id,
              })),
            );
          tags.push({ type: "LeaveBalanceAll" });
          return tags;
        },
        query: (filter) => {
          const allQuery = `/get?${Object.entries(filter || {})
            .map((val) =>
              !["", "undefined"].includes(val[0]) ? `${val[0]}=${val[1]}` : "",
            )
            .join("&")}`;

          return {
            // url: `/get?year=${filter.year}&location=${filter.location}&department=${filter.department}&search=${filter.search}`,
            url: allQuery,
            method: "GET",
          };
        },
      }),
      // fetchExportLeaveBalance: builder.query({
      //   query: (filter) => {
      //     return {
      //       url: `/get?export=true&${Object.entries(filter || {}).map((val) => !["", "undefined"].includes(val[0]) ? `${val[0]}=${val[1]}` : "").join("&")}`,
      //       method: "GET",
      //     };
      //   },
      // }),
      fetchLeaveBalanceUser: builder.query({
        query: (param) => {
          return {
            url: `/get/${param.id}`,
            method: "GET",
          };
        },
      }),
      fetchLeaveExpired: builder.query({
        query: (param) => {
          return {
            // url: `/get-expired?year=${param.year}&location=${param.location}&department=${param.department}&search=${param.search}`,
            url: `/get-expired?${Object.entries(param || {})
              .map((val) =>
                !["", "undefined"].includes(val[0])
                  ? `${val[0]}=${val[1]}`
                  : "",
              )
              .join("&")}`,
            method: "GET",
          };
        },
      }),
      // fetchExportLeaveExpired: builder.query({
      //   query: (param) => {
      //     return {
      //       url: `/get-expired?export=true&${Object.entries(param || {}).map((val) => !["", "undefined"].includes(val[0]) ? `${val[0]}=${val[1]}` : "").join("&")}`,
      //       method: "GET",
      //     };
      //   },
      // }),
      addLeaveBalance: builder.mutation({
        query: (body) => {
          return {
            url: "/create",
            method: "POST",
            body: {},
          };
        },
      }),
      updateLeaveBalance: builder.mutation({
        query: (body) => {
          return {
            url: `/adjustment/${body.userID}`,
            method: "PUT",
            body: {
              data: body.payload,
              note: body.note,
            },
          };
        },
      }),
    };
  },
});

export const {
  useFetchLeaveBalanceQuery,
  // useFetchExportLeaveBalanceQuery,
  useFetchLeaveBalanceUserQuery,
  useFetchLeaveExpiredQuery,
  // useFetchExportLeaveExpiredQuery,
  useAddLeaveBalanceMutation,
  useUpdateLeaveBalanceMutation,
} = leaveBalanceApi;
export { leaveBalanceApi };
