import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "services/axios";

const ENDPOINT = process.env.REACT_APP_API_URL;
const API = `${ENDPOINT}/api/leave-log`;

const leaveLogApi = createApi({
  reducerPath: "leaveLog",
  baseQuery: axiosBaseQuery({
    baseUrl: API,
    fetchFn: async (...args) => {
      return fetch(...args);
    },
  }),
  endpoints(builder) {
    return {
      getLeaveLogs: builder.query({
        providesTags: (result, error, args) => {
          const tags = [{ type: "LeaveLog", id: "LIST" }];

          if (args.startDate && args.endDate) {
            tags.push({
              type: "LeaveLog",
              id: `DATE-${args.startDate}-${args.endDate}`,
            });
          }

          if (args.keyword) {
            tags.push({ type: "LeaveLog", id: `KEYWORD-${args.keyword}` });
          }

          return tags;
        },
        query: ({
          startDate,
          endDate,
          keyword,
          page = 1,
          limit = 10,
        } = {}) => ({
          url: `?startDate=${startDate}&endDate=${endDate}&keyword=${keyword}&page=${page}&limit=${limit}`,
          method: "GET",
        }),
      }),
    };
  },
});

export const { useGetLeaveLogsQuery } = leaveLogApi;
export { leaveLogApi };
