import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "services/axios";

const ENDPOINT = process.env.REACT_APP_API_URL;
const API = `${ENDPOINT}/api`;

const externalApi = createApi({
  reducerPath: "external",
  baseQuery: axiosBaseQuery({
    baseUrl: API,
  }),
  endpoints(builder) {
    return {
      fetchLocationDepartment: builder.query({
        query: (filter) => {
          return {
            url: `/employee/location`,
            method: "GET",
          };
        },
      }),
      fetchShiftByUserID: builder.query({
        query: (fetchShiftByUserID) => {
          return {
            url: `/scheduling/get-scheduling-all/${fetchShiftByUserID.fetchShiftByUserID}`,
            method: "GET",
          };
        },
      }),

      fetchShiftByUserIDAndDate: builder.query({
        query: (fetchShiftByUserID) => {
          return {
            url: `/scheduling/get-scheduling/${fetchShiftByUserID.fetchShiftByUserID}`,
            method: "GET",
            params: {
              startDate: fetchShiftByUserID.startDate,
              endDate: fetchShiftByUserID.endDate,
            },
          };
        },
      }),

      fetchLatestShiftNameByUserID: builder.query({
        query: (fetchShiftByUserID) => {
          return {
            url: `/scheduling/latest-shift-name/${fetchShiftByUserID.fetchShiftByUserID}`,
            method: "GET",
          };
        },
      }),
    };
  },
});

export const {
  useFetchLocationDepartmentQuery,
  useFetchShiftByUserIDQuery,
  useFetchShiftByUserIDAndDateQuery,
  useFetchLatestShiftNameByUserIDQuery,
} = externalApi;
export { externalApi };
