import React from "react";
import {
  DataTable,
  ModalDialog,
  Pagination,
  Tooltip,
} from "@bluesilodev/timhutcomponents";
import { useNavigate } from "react-router-dom";
import InformCircleSVG from "assets/icon/InformCircleSVG/InformCircleSVG";

import TableMenu from "components/tableMenu";

export const LeaveBalanceDetailsDataTable = ({ leaveBalanceDetailsValue }) => {
  const cellDay = (value) => {
    return <React.Fragment>{value} Day</React.Fragment>;
  };

  return (
    <React.Fragment>
      <DataTable
        columns={[
          {
            id: "leaveType",
            accessorFn: (row) => <h1>{row.leaveTypeName}</h1>,
            enableSorting: true,
            header: () => <span>Leave Type</span>,
            cell: (status) => {
              return status.getValue();
            },
          },
          {
            id: "fullYearEntitlement",
            accessorFn: (row) => row.fullYearEntitlement,
            enableSorting: true,
            header: () => <span>Full Year Entitlement</span>,
            cell: (status) => {
              return cellDay(status.getValue());
            },
          },
          {
            id: "ytdEarned",
            accessorFn: (row) => row.ytdEarned,
            enableSorting: true,
            header: () => <span>YTD Earned</span>,
            cell: (status) => {
              return cellDay(status.getValue());
            },
          },
          {
            id: "broughtFoward",
            accessorFn: (row) => row.broughtForward,
            enableSorting: true,
            header: () => <span>Brought Foward</span>,
            cell: (status) => {
              return cellDay(status.getValue());
            },
          },
          {
            id: "adjusment",
            accessorFn: (row) => row.adjustment,
            enableSorting: true,
            header: () => <span>Adjusment</span>,
            cell: (status) => {
              return cellDay(status.getValue());
            },
          },
          {
            id: "ytdTaken",
            accessorFn: (row) => row.ytdTaken,
            enableSorting: true,
            header: () => <span>YTD Taken</span>,
            cell: (status) => {
              return cellDay(status.getValue());
            },
          },
          {
            id: "bfForfeinted",
            accessorFn: (row) => row,
            enableSorting: true,
            header: () => <span>B/F Forfeited</span>,
            cell: (status) => {
              return (
                <div className="flex flex-row items-center gap-2">
                  <h1>{status.getValue().forfeitedBF} Day</h1>
                  <Tooltip
                    content={
                      <div className="p-3">
                        <div className="mb-2 text-xs font-bold text-black whitespace-nowrap">
                          Leave Expired
                        </div>
                        <p className="text-xs font-bold text-orange-500 whitespace-nowrap">
                          (Unused Carry Over will expired on 01/
                          {`0${status.getValue().expiredCarryOver}`.slice(-2)}/
                          {new Date().getFullYear()})
                        </p>
                      </div>
                    }
                    children={<InformCircleSVG color={"black"} />}
                    position="bottom"
                  />
                </div>
              );
            },
          },
          {
            id: "pendingForApproval",
            accessorFn: (row) => row.pendingForApproval,
            enableSorting: true,
            header: () => <span>Pending For Approval</span>,
            cell: (status) => {
              return cellDay(status.getValue());
            },
          },
          {
            id: "ytdBalance",
            accessorFn: (row) => row.ytdBalance,
            enableSorting: true,
            header: () => <span>YTD Balance</span>,
            cell: (status) => {
              return (
                <h1 className="font-semibold">{cellDay(status.getValue())}</h1>
              );
            },
          },
          {
            id: "projectedBalance",
            accessorFn: (row) => row.projectedBalance,
            enableSorting: true,
            header: () => <span>Projected Balance</span>,
            cell: (status) => {
              return cellDay(status.getValue());
            },
          },
        ]}
        data={leaveBalanceDetailsValue}
      />

      <div className="mt-5 text-sm font-semibold">
        <h1>Notes:</h1>
        <h1>
          YTD Balance = (YTD Earned + Brought Forward + Adjustment) - (YTD Taken
          + B/F Forfeited + Pending Approval)
        </h1>
        <h1>
          Projected Balance = (Full Year Entitlement + Brought Forward +
          Adjustment) - (YTD Taken + B/F Forfeited + Pending Approval)
        </h1>
      </div>
    </React.Fragment>
  );
};

const LeaveBalanceDataTable = ({
  dataTable,
  totalData,
  rowsPerPage,
  currentPage,
  onChangeCurrentPage,
  onChangeRowsPerPage,
  additionalLeaveTypeColumn,
}) => {
  // console.log("data", dataTable);
  const navigate = useNavigate();

  function formatNumber(num) {
    // Check if number have more than 2 desimal
    const parseNum = Number(num);

    if (parseNum % 1 !== 0 && parseNum.toString().split(".")[1]?.length > 2) {
      return parseFloat(parseNum.toFixed(2));
    }
    return parseNum;
  }

  // function formatNumber(num) {
  //   if (num % 1 !== 0 && num.toString().split(".")[1]?.length > 2) {
  //     return Number(num.toFixed(2)); 
  //   }
  //   return num;
  // }

  const changeName = (status) => {
    return (
      <h1 className="w-full cursor-pointer">
        <span>
          {status.getValue()
            ? `${formatNumber(status.getValue())} Day`
            : `0 Day`}
        </span>
      </h1>
    );
  };

  return (
    <>
      <DataTable
        title="Leave Data"
        className="max-h-[calc(100dvh-285px)] "
        columns={[
          {
            id: "employee",
            accessorFn: (row) => row,
            enableSorting: undefined,
            header: () => <span>Employee</span>,
            noPadding: true,

            cell: (status) => {
              const employee = status.getValue();
              return (
                <div className="flex flex-row gap-2 justify-center items-center h-[20px]">
                  <img
                    src={employee.photo}
                    alt="ProfileUser"
                    className="w-6 h-6 my-auto ml-4 rounded-full"
                  />
                  <span>{employee.employee}</span>
                </div>
              );
            },
          },
          {
            id: "uId",
            accessorFn: (row) => row.employeeID,
            header: () => <span>Employee ID</span>,
            enableSorting: true,
            cell: (status) => {
              return (
                <h1 className="w-full cursor-pointer">{status.getValue()}</h1>
              );
            },
          },
          {
            id: "jobPosition",
            accessorFn: (row) => row.jobPosition,
            header: () => <span>Job Position</span>,
            enableSorting: true,
            noPadding: true,
            cell: (status) => {
              // const index = status.row.index;
              const department = status.getValue().department;
              return (
                <div className="w-full cursor-pointer h-[24px]">
                  <h1 className="">{status.getValue().position}</h1>
                  <h1 className="text-gray-400 text-s">{department}</h1>
                </div>
              );
            },
          },
          ...additionalLeaveTypeColumn.map((itemType) => {
            return {
              id: itemType.name,
              accessorFn: (row) => {
                const leaveYtdBalance =
                  row[itemType.name] !== undefined &&
                  row[itemType.name].ytdBalance === 0
                    ? `${row[itemType.name].ytdBalance}`
                    : row[itemType.name].ytdBalance;

                // if (itemType.name.trim() === "Annual Leave") {
                //   console.log("ytdbalance", row[itemType.name].ytdBalance);
                // }

                // return row[itemType.name] !== undefined &&
                //   row[itemType.name].ytdBalance !== 0
                //   ? row[itemType.name].ytdBalance
                //   : `${row[itemType.name].ytdBalance} Day`;
                return leaveYtdBalance;
              },
              header: () => <span>{itemType.name} </span>,
              enableSorting: true,
              cell: (status) => {
                return changeName(status);
              },
            };
          }),
          /*
          {
            id: "annualLeave",
            accessorFn: (row) => `${row.annualLeave} Day`,
            header: () => <span>Annual Leave</span>,
            enableSorting: true,
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "sickLeave",
            accessorFn: (row) => `${row.sickLeave} Day`,
            header: () => <span>Sick Leave</span>,
            enableSorting: true,
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "offInLine",
            accessorFn: (row) => `${row.offInLine} Day`,
            header: () => <span>Off In Line</span>,
            enableSorting: true,
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "ns",
            accessorFn: (row) => `${row.ns} Day`,
            header: () => <span>NS</span>,
            enableSorting: true,
            cell: (status) => {
              return cell(status);
            },
          },
          */
          {
            accessorFn: (row) => row.userID,
            disableSorting: true,
            header: () => {},
            id: "action",
            noPadding: true,
            cell: (status) => {
              return (
                <div className="cursor-pointer w-fit h-[20px]">
                  <TableMenu
                    onDetail={() => {
                      navigate(`/leave-balance-details/${status.getValue()}`);
                    }}
                  />
                </div>
              );
            },
          },
        ]}
        data={dataTable}
        pagination={false}
      />
      <div className="py-2" />
      <Pagination
        totalData={totalData}
        rowsPerPage={rowsPerPage}
        currentPage={currentPage}
        onChangeCurrentPage={onChangeCurrentPage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </>
  );
};

export default LeaveBalanceDataTable;
