import React from "react";
import { LeaveTypeSettingsForm } from "@bluesilodev/timhutcomponents";
import { useAlertApi } from "services/alert-api";
import {
  useAddLeaveTypeMutation,
  useUpdateLeaveTypeMutation,
} from "store/apis/leaveTypeApi";
import { useFetchEmployeeStatusQuery } from "store";

export const LeaveTypeAddModalForm = ({
  addLeaveTypeModal,
  addLeaveTypeOpen,
}) => {
  const [employeeStatus, setEmployeeStatus] = React.useState([]);
  const [addLeaveType, addLeaveTypeResults] = useAddLeaveTypeMutation();

  const {
    data: dataEmployeeStatus,
    error: errorEmployeeStatus,
    isFetching: isFetchingEmployeeStatus,
    isLoading: isLoadingEmployeeStatus,
  } = useFetchEmployeeStatusQuery();

  React.useEffect(() => {
    if (
      !dataEmployeeStatus ||
      !dataEmployeeStatus.data ||
      !Array.isArray(dataEmployeeStatus.data.data)
    ) {
      console.warn("Invalid data structure, resetting employee status.");
      setEmployeeStatus([]);
      return;
    }

    console.log("Valid employee status data:", dataEmployeeStatus.data.data);

    const mappedStatus = dataEmployeeStatus.data.data
      .filter((item) => item?.employeeType) // Ensure employeeType exists
      .map((item) => ({
        label: item.employeeType,
        value: item.employeeType,
      }));

    setEmployeeStatus(mappedStatus);
  }, [dataEmployeeStatus]);

  const alertSuccessClose = () => {
    addLeaveTypeOpen(false);
  };

  useAlertApi(
    addLeaveTypeResults,
    alertSuccessClose,
    () => {},
    () => "Leave type added successfully",
  );

  const clickSaveAddLeaveType = (values) => {
    // console.log("values", values.assignTo);
    addLeaveType({
      name: values.name,
      defaultAmount: values.defaultAmount,
      // gainPerMonth: values.gainPerMonth,
      expiredCarryOver: values.expiredCarryOver,
      maxCarryOver: values.maxCarryOver,
      isFullLeaveAmount: values.isFullLeaveAmount,
      periodOfEmployments: values.periodOfEmployments.map((val) => ({
        from: val.from,
        to: val.to,
        equal: val.equal,
      })),
      assignTo: values?.assignTo,
    });
  };

  return (
    <React.Fragment>
      {addLeaveTypeModal && (
        <LeaveTypeSettingsForm
          visibleModal={addLeaveTypeModal}
          setVisibleModal={addLeaveTypeOpen}
          initialValue={{ expiredCarryOver: 0 }}
          onSubmit={clickSaveAddLeaveType}
          type={"add"}
          assignToOptions={employeeStatus}
        />
      )}
    </React.Fragment>
  );
};

export const LeaveTypeEditModalForm = ({
  leaveTypeDetailsModal,
  leaveTypeDetailsOpen,
  leaveTypeDetailsEdit,
}) => {
  const [employeeStatus, setEmployeeStatus] = React.useState([]);

  const {
    data: dataEmployeeStatus,
    error: errorEmployeeStatus,
    isFetching: isFetchingEmployeeStatus,
    isLoading: isLoadingEmployeeStatus,
  } = useFetchEmployeeStatusQuery();

  React.useEffect(() => {
    if (
      !dataEmployeeStatus ||
      !dataEmployeeStatus.data ||
      !Array.isArray(dataEmployeeStatus.data.data)
    ) {
      console.warn("Invalid data structure, resetting employee status.");
      setEmployeeStatus([]);
      return;
    }

    const mappedStatus = dataEmployeeStatus.data.data
      .filter((item) => item?.employeeType) // Ensure employeeType exists
      .map((item) => ({
        label: item.employeeType,
        value: item.employeeType,
      }));

    setEmployeeStatus(mappedStatus);
  }, [dataEmployeeStatus]);

  const [updateLeaveType, updateLeaveTypeResults] =
    useUpdateLeaveTypeMutation();

  const alertSuccessClose = () => {
    leaveTypeDetailsOpen(false);
  };

  useAlertApi(
    updateLeaveTypeResults,
    alertSuccessClose,
    () => {},
    () => "Leave type updated successfully",
  );

  const clickSaveEditLeaveType = (values) => {
    updateLeaveType({
      leaveTypeId: values.leaveTypeId,
      name: values.name,
      defaultAmount: values.defaultAmount,
      // gainPerMonth: values.gainPerMonth,
      expiredCarryOver: values.expiredCarryOver,
      maxCarryOver: values.maxCarryOver,
      isFullLeaveAmount: values.isFullLeaveAmount,
      periodOfEmployments: values.periodOfEmployments.map((val) => ({
        from: val.from,
        to: val.to,
        equal: val.equal,
      })),
      assignTo: values?.assignTo || [],
    });
  };

  return (
    <React.Fragment>
      {leaveTypeDetailsModal && (
        <LeaveTypeSettingsForm
          visibleModal={leaveTypeDetailsModal}
          setVisibleModal={leaveTypeDetailsOpen}
          initialValue={leaveTypeDetailsEdit}
          onSubmit={clickSaveEditLeaveType}
          type={"edit"}
          assignToOptions={employeeStatus}
        />
      )}
    </React.Fragment>
  );
};
