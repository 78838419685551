import React from "react";
import { DataTable, Pagination } from "@bluesilodev/timhutcomponents";
import { useNavigate } from "react-router-dom";

import TableMenu from "components/tableMenu";

const WorkingDayDataTable = ({
  dataTable,
  totalData,
  rowsPerPage,
  currentPage,
  onChangeCurrentPage,
  onChangeRowsPerPage,
  additionalLeaveTypeColumn,
}) => {
  // console.log("data", dataTable);
  // const navigate = useNavigate();
  const changeName = (status) => {
    return (
      <h1 className="w-full cursor-pointer">
        <span>{status.getValue() ? `${status.getValue()} Day` : `0 Day`}</span>
      </h1>
    );
  };

  return (
    <>
      <DataTable
        title="Leave Data"
        className="max-h-[calc(100dvh-285px)] "
        columns={[
          {
            id: "employee",
            accessorFn: (row) => row,
            enableSorting: undefined,
            header: () => <span>Employee</span>,
            noPadding: true,

            cell: (status) => {
              const employee = status.getValue();
              return (
                <div className="flex flex-row gap-2 justify-center items-center h-[20px]">
                  <img
                    src={employee.photo}
                    alt="ProfileUser"
                    className="w-6 h-6 my-auto ml-4 rounded-full"
                  />
                  <span>{employee.employee}</span>
                </div>
              );
            },
          },
          {
            id: "uId",
            accessorFn: (row) => row.employeeID,
            header: () => <span>Employee ID</span>,
            enableSorting: true,
            cell: (status) => {
              return (
                <h1 className="w-full cursor-pointer">{status.getValue()}</h1>
              );
            },
          },
          {
            id: "jobPosition",
            accessorFn: (row) => row.jobPosition,
            header: () => <span>Job Position</span>,
            enableSorting: true,
            noPadding: true,
            cell: (status) => {
              // const index = status.row.index;
              const department = status.getValue().department;
              return (
                <div className="w-full cursor-pointer h-[24px]">
                  <h1 className="">{status.getValue().position}</h1>
                  <h1 className="text-gray-400 text-s">{department}</h1>
                </div>
              );
            },
          },
          {
            id: "totalDaysWork",
            accessorFn: (row) => row.totalDaysWork,
            header: () => <span>Total Days Work</span>,
            enableSorting: true,
            noPadding: true,
            cell: (status) => {
              return (
                <h1 className="w-full cursor-pointer">{status.getValue()}</h1>
              );
            },
          },
          // ...additionalLeaveTypeColumn.map((itemType) => ({
          //   id: itemType.name,
          //   accessorFn: (row) => {
          //     const matchingLeaveRecord = row.leaveRecords.find(
          //       (record) => record.leaveTypeID === itemType.uId,
          //     );
          //     return matchingLeaveRecord ? matchingLeaveRecord.ytdTaken : 0; // Default to 0 if no matching record
          //   },
          //   header: () => <span>{itemType.name}</span>,
          //   enableSorting: true,
          //   cell: (status) => (
          //     <h1 className="text-center">{status.getValue()}</h1>
          //   ),
          // })),
          ...additionalLeaveTypeColumn.map((itemType) => ({
            id: itemType.name,
            accessorFn: (row) => {
              if (!row.leaveRecords || row.leaveRecords.length === 0) {
                return 0; // Default to 0 if leaveRecords is an empty array
              }

              const matchingLeaveRecord = row.leaveRecords.find(
                (record) => record.leaveTypeID === itemType.uId,
              );

              return matchingLeaveRecord ? matchingLeaveRecord.ytdTaken : 0; // Default to 0 if no matching record
            },
            header: () => <span>{itemType.name}</span>,
            enableSorting: true,
            cell: (status) => (
              <h1 className="text-center">{status.getValue()}</h1>
            ),
          })),
          // {
          //   accessorFn: (row) => row.userID,
          //   disableSorting: true,
          //   header: () => {},
          //   id: "action",
          //   noPadding: true,
          //   cell: (status) => {
          //     return (
          //       <div className="cursor-pointer w-fit h-[20px]">
          //         <TableMenu
          //         // onDetail={() => {
          //         //   navigate(`/leave-balance-details/${status.getValue()}`);
          //         // }}
          //         />
          //       </div>
          //     );
          //   },
          // },
        ]}
        data={dataTable}
        pagination={false}
      />
      <div className="py-2" />
      <Pagination
        totalData={totalData}
        rowsPerPage={rowsPerPage}
        currentPage={currentPage}
        onChangeCurrentPage={onChangeCurrentPage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </>
  );
};

export default WorkingDayDataTable;
