import {
  InputSelect,
  InputDateRange,
  SearchBox,
  Button,
} from "@bluesilodev/timhutcomponents";
import UserAddIcon from "assets/icon/UserAddSVG/UserAdd";
import LeaveLogDataTable from "./tables";
import { useState } from "react";
import dayjs from "dayjs";
import { useDebounce } from "./hooks/useDebounce";
import { useGetLeaveLogsQuery } from "store/apis/leaveLogApi";
import axios from "services/axios";
import { objectToQueryStringUrl } from "utils/common";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const LeaveLog = () => {
  const { currentRole } = useSelector((state) => state.userData);
  const navigate = useNavigate();
  const [filterTimeRange, setFilterTimeRange] = useState({
    startDate: dayjs().startOf("month").toDate(),
    endDate: dayjs().endOf("month").toDate(),
  });
  const [paginate, setPaginate] = useState({ rowsPerPage: 10, currentPage: 1 });
  const [keyword, setKeyword] = useState("");
  const debouncedKeyword = useDebounce(keyword, 500);

  const query = useMemo(
    () => ({
      startDate: dayjs(filterTimeRange.startDate).format("YYYY-MM-DD"),
      endDate: dayjs(filterTimeRange.endDate).format("YYYY-MM-DD"),
      keyword: debouncedKeyword,
      page: paginate.currentPage,
      limit: paginate.rowsPerPage,
    }),
    [filterTimeRange, paginate, debouncedKeyword],
  );

  const { data: leaveLogs } = useGetLeaveLogsQuery(query);

  const handleOnExport = () => {
    axios
      .get(
        `/api/leave-log?${objectToQueryStringUrl({ ...query, export: true })}`,
      )
      .then((response) => {
        const href = URL.createObjectURL(
          new Blob([response.data], { type: "text/csv;charset=utf-8," }),
        );
        const filename = [
          `[${dayjs(filterTimeRange.startDate).format("DD-MMM-YYYY")}_${dayjs(
            filterTimeRange.endDate,
          ).format("DD-MMM-YYYY")}]`,
          `[Page_${query.page}]`,
        ];
        if (keyword) filename.push(`['${keyword}']`);

        const link = document.createElement("a");
        link.href = href;
        link.setAttribute(
          "download",
          `Timhut_Leave_Log_${filename.join("_")}..csv`,
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  };

  if (currentRole !== "Admin") {
    navigate("/");
  }

  return (
    <div className="relative w-full p-1 mt-5 h-max">
      <div className="flex justify-between w-full">
        <div className="flex gap-5 mr-5">
          <div className="w-full">
            <div className="w-[350px] [&>div>div>div]:flex-1">
              <InputDateRange
                label={"Time Range"}
                value={[filterTimeRange.startDate, filterTimeRange.endDate]}
                setFieldValue={(_, resultArrayDate) => {
                  setFilterTimeRange((oldV) => ({
                    startDate: resultArrayDate[0],
                    endDate: resultArrayDate[resultArrayDate.length - 1],
                  }));
                }}
                error={""}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-row gap-5">
          <SearchBox
            className={"w-[300px]"}
            placeholder="Search"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
          />

          <Button
            className="w-[150px] bg-white text-[#DD7224] hover:text-white hover:bg-[#DD7224]"
            onClick={handleOnExport}
            label={
              <div className="flex gap-1 px-4">
                <UserAddIcon color="currentColor" />
                <div>Export</div>
              </div>
            }
          />
        </div>
      </div>

      <LeaveLogDataTable
        data={leaveLogs?.data || []}
        currentPage={paginate.currentPage}
        rowsPerPage={paginate.rowsPerPage}
        totalData={leaveLogs?.totalDocs}
        onChangeCurrentPage={(page) =>
          setPaginate({ ...paginate, currentPage: page })
        }
        onChangeRowsPerPage={(rowsPerPage) =>
          setPaginate({ ...paginate, rowsPerPage })
        }
      />
    </div>
  );
};

export default LeaveLog;
