export function objectToQueryStringUrl(params) {
  return Object.entries(params)
    .reduce((acc, [key, value]) => {
      if (value != null && value !== "") {
        acc.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
      }
      return acc;
    }, [])
    .join("&");
}

// Function to get first and last day of a month
export const getMonthDateRange = (year, month) => {
  const firstDay = new Date(year, month - 1, 2).toISOString().split("T")[0]; // Convert to YYYY-MM-DD
  const lastDay = new Date(year, month, 1).toISOString().split("T")[0]; // Convert to YYYY-MM-DD
  // console.log("first day", firstDay);
  return { firstDay, lastDay };
};

// export const getMonthDateRange = (year, month) => {
//   const firstDay = new Date(year, month - 1, 1);
//   const lastDay = new Date(year, month, 0);

//   // Manually extract YYYY-MM-DD
//   const formatDate = (date) => {
//     const yyyy = date.getFullYear();
//     const mm = String(date.getMonth() + 1).padStart(2, "0"); // Ensure 2-digit month
//     const dd = String(date.getDate()).padStart(2, "0"); // Ensure 2-digit day
//     return `${yyyy}-${mm}-${dd}`;
//   };

//   return {
//     startDate: formatDate(firstDay),
//     endDate: formatDate(lastDay),
//   };
// };
