import dayjs from "dayjs";

const { DataTable, Pagination } = require("@bluesilodev/timhutcomponents");

const LEAVE_LOG_COLUMNS = [
  {
    id: "actionTime",
    accessorFn: (row) =>
      row?.createdAt
        ? dayjs(row?.createdAt).format("MMM. D, YYYY, h:mm a")
        : "",
    header: () => <span>Action Time</span>,
    enableSorting: true,
  },
  {
    id: "employee",
    accessorFn: (row) => row?.firstName || "",
    header: () => <span>Employee</span>,
    enableSorting: true,
  },
  {
    id: "jobPosition",
    accessorFn: (row) => row?.jobPosition || "",
    header: () => <span>Job Position</span>,
    enableSorting: true,
  },
  {
    id: "department",
    accessorFn: (row) => row?.department || "",
    header: () => <span>Department</span>,
    enableSorting: true,
  },

  {
    id: "leaveType",
    accessorFn: (row) => row?.leaveType || "",
    header: () => <span>Leave Type</span>,
    enableSorting: true,
  },
  {
    id: "balanceBefore",
    accessorFn: (row) => row?.balanceBefore || "",
    header: () => <span>Balance Before</span>,
    enableSorting: true,
  },
  {
    id: "balanceAfter",
    accessorFn: (row) => row?.balanceAfter || "",
    header: () => <span>Balance After</span>,
    enableSorting: true,
  },
];

const LeaveLogDataTable = ({
  data,
  totalData,
  rowsPerPage,
  currentPage,
  onChangeCurrentPage,
  onChangeRowsPerPage,
}) => {
  return (
    <div className="w-full mt-5">
      <DataTable
        className="max-h-[calc(100dvh-285px)]"
        columns={LEAVE_LOG_COLUMNS}
        data={data}
        pagination={false}
      />
      <div className="py-2" />
      <Pagination
        totalData={totalData}
        rowsPerPage={rowsPerPage}
        currentPage={currentPage}
        onChangeCurrentPage={onChangeCurrentPage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </div>
  );
};

export default LeaveLogDataTable;
