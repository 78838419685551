import React from "react";
import { alertSuccess, alertError } from "utils/alert";

export const useAlertApi = (
  queryApiMutationResults,
  onSuccess,
  onError,
  title, // Pastikan title diterima sebagai parameter
) => {
  React.useEffect(() => {

    if (queryApiMutationResults.isSuccess) {
      alertSuccess(title(), queryApiMutationResults.data?.message);
      onSuccess?.();
      // executeAlertSuccess(title, queryApiMutationResults.data?.message);
    }

    if (queryApiMutationResults.isError) {
      executeAlertError("Error", queryApiMutationResults.error?.data?.message);
    }
  }, [queryApiMutationResults]);

  // const executeAlertSuccess = (alertTitle, message) => {
  //   console.log("Executing success alert:", alertTitle, message); // Debugging
  //   alertSuccess(alertTitle, message);
  //   onSuccess?.();
  // };

  const executeAlertError = (alertTitle, message) => {
    console.log("Executing error alert:", alertTitle, message); // Debugging
    alertError(alertTitle, message);
    onError?.();
  };
};
